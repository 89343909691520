<template>
    <div class="ma-auto">
        <div class="d-flex align-center justify-center mb-4">
            <div class="error-title">
                <h1 class="font-weight-bold mb-0">404</h1>
                <div class="text-18 font-weight-bold">Not Found!</div>
            </div>
        </div>
        <div class="d-flex justify-center flex-wrap">
            <v-btn class="ma-2" color="primary"
                >Back To Dashboard</v-btn
            >
            <v-btn class="ma-2" color="danger"
                >Report the problem</v-btn
            >
        </div>
    </div>
</template>
<script>
export default {
    name: 'Error',
    metaInfo: {
        title: 'Error'
    }
}
</script>
<style lang="scss" scoped>
</style>
